import { render, staticRenderFns } from "./TournamentCreateModal.vue?vue&type=template&id=531a6526&scoped=true"
import script from "./TournamentCreateModal.vue?vue&type=script&lang=js"
export * from "./TournamentCreateModal.vue?vue&type=script&lang=js"
import style0 from "./TournamentCreateModal.vue?vue&type=style&index=0&id=531a6526&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531a6526",
  null
  
)

export default component.exports